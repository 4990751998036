export default function About() {
  return (
    <section id="about">
      <div className="w-full max-h-500">
        <img
          src="/img/tud-campus.jpeg"
          alt="Colliers India Report REITs"
          className="max-h-[66vh] w-full object-cover object-center"
        />
      </div>

      <div className="bg-white py-20">
        <div className="container mx-auto px-4 max-w-4xl">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-6 text-left">About us</h1>
            <p className="text-lg text-left">
              Welcome to the website of the{" "}
              <strong>Research Engineering and Infrastructure Team</strong>{" "}
              (REIT) at the Faculty of Electrical Engineering, Mathematics and
              Computer Science at TU Delft. We are a diverse team of Research
              Engineers dedicated to support students and researchers with our
              expertise in software engineering, data science, and
              high-performance computing. Our mission is to enable researchers
              to focus on their research by providing them with the best
              possible research infrastructure and support.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
