// App.js
import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";

// Import your components
import Navbar from "./components/Navbar";
import About from "./components/About";
import Jobs from "./components/Jobs";
import QuickConnect from "./components/QuickConnect";
import Services from "./components/Services";
import Members from "./components/Members";
import Projects from "./components/Projects";
import Courses from "./components/Courses";
import Workwithus from "./components/Workwithus";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MemberDetails from "./components/MemberDetails";

export default function App() {
  return (
    <main className="text-gray-700 bg-white body-font">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <About />
              <QuickConnect />
              <Services />
              <Workwithus />
              <QuickConnect />
              <Members />
              <Jobs />
              <Projects />
              <Courses />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route path="/members/:memberSlug" element={<MemberDetails />} />
      </Routes>
    </main>
  );
}
