import React from "react";
import { BeakerIcon as Icon } from "@heroicons/react/24/solid";

export default function Workwithus() {
  return (
    <section id="workwithus" className="py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <Icon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">
            Initiating a Project with Us
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            Initiating a project with us is easy. Our project approval process
            can be summarized in four simple steps:
          </p>
        </div>
        <div className="bg-gray-100 py-10 mx-auto mt-8 px-10 max-w-[600px] shadow-md rounded-lg">
          <div className="text-left mb-4">
            <h3 className="text-lg font-bold mb-2">1. Reach out to Us</h3>
            <p className="text-base leading-relaxed text-gray-600">
              Contact us either by talking to us directly or writing an{" "}
              <a
                href="mailto:reit-ewi@tudelft.nl"
                className="text-blue-500 hover:underline"
              >
                e-mail
              </a>
              .
            </p>
          </div>
          <div className="text-left mb-4">
            <h3 className="text-lg font-bold mb-2">
              2. Fill out an Intake-Form
            </h3>
            <p className="text-base leading-relaxed text-gray-600">
              One of our Research Engineers will assist you in filling out the
              <a
                href="https://forms.office.com/e/p4dWbT22i2"
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                project intake-form
              </a>
              .
            </p>
          </div>
          <div className="text-left mb-4">
            <h3 className="text-lg font-bold mb-2">3. Internal Evaluation</h3>
            <p className="text-base leading-relaxed text-gray-600">
              We'll evaluate the request internally and may follow up with
              additional questions.
            </p>
          </div>
          <div className="text-left mb-4">
            <h3 className="text-lg font-bold mb-2">
              4. Approval and Scheduling
            </h3>
            <p className="text-base leading-relaxed text-gray-600">
              Upon approval, we'll schedule the request and notify you
              accordingly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
