import React from "react";
import { DocumentTextIcon as Icon } from "@heroicons/react/24/solid";
import { positions } from "../data/open-positions";

export default function Jobs() {
  return (
    <section id="jobs" className="bg-white py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <Icon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">Open Positions</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            Explore our current job openings below. If you find a position that
            matches your skills and interests, we invite you to apply and become
            a part of our dynamic team. Don't see a suitable opening? Feel free
            to submit your resume for future consideration as we're continuously
            growing and looking for new talent.
          </p>
        </div>

        <div className="flex flex-wrap gap-12 justify-center">
          {positions.length === 0 ? (
            <div className="p-4 bg-gray-100 rounded-lg">
              <p className="text-lg font-medium text-gray-700">
                No open positions at this time
              </p>
            </div>
          ) : (
            positions.map((position, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden max-w-md w-full"
              >
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-3 text-gray-700">
                    {position.name}
                  </h2>
                  <p className="text-gray-600 mb-4">{position.desc}</p>
                  <a
                    href={position.link}
                    className="text-blue-500 mt-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="bg-custom-blue hover:bg-custom-blue-dark text-white font-bold py-3 px-8 rounded-full transition duration-300">
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
}
