export const projects = [
  {
    name: "CropXR",
    image: "img/project-cropxr.jpeg",
    desc: "The CropXR project, backed by the Netherlands Organisation for Scientific Research (NWO), is a pioneering initiative in the realm of agriculture, set to revolutionize plant breeding to address global challenges. The initiative aims at enhancing the resilience of crops, enabling them to thrive under increasingly harsh and unpredictable environmental conditions.",
  },

  {
    name: "Energy-SHR",
    image: "img/project-energy-shr.png",
    desc: "The Convergence collaboration between TU Delft and Erasmus University Rotterdam has taken the initiative to develop a platform where researchers on the energy transition can share (publish and/or find) energy-related datasets and algorithms: EnergySHR.nl. The aim of this platform is to accelerate research into smart, data-driven algorithms.",
  },

  {
    name: "Spoofax",
    image: "img/project-spoofax.svg",
    desc: "Spoofax is an open-source language designer's workbench with everything you need for designing your next textual (domain-specific) programming language. It provides a platform for high-level language definition and experimentation with linguistic abstractions for language engineering. ",
  },

  {
    name: "Ivory Forest",
    image: "img/project-ivory-forest.jpg",
    desc: "Ivory Forest is an open source combined virtual environment builder and game built in Unity 3D, serving as a framework for the setup of cognitive studies with (human and nonhuman) primates. This project is a collaboration with the University of Amsterdam.",
  },
  {
    name: "Herb",
    image: "img/project-herb.svg",
    desc: "Herb is an open-source Julia library for program synthesis. It provides a unified framework for describing program synthesis problems and offers ready-to-use implementations for state-of-the-art benchmarks and solvers.",
  },

  {
    name: "DAIC support",
    image: "img/project-daic.png",
    desc: "DAIC, formerly INSY-HPC or HPC, is a TU Delft High Performance Computing (HPC) cluster for running large-scale or GPU-reliant tasks. Initiated within the INSY (EWI) department and later expanded in collaborations across TU Delft, DAIC is exclusive to users from these participating departments. REIT supports CS DAIC users and the ongoing enhancement of thiss infrastructure.",
  },

  {
    name: "NEON",
    image: "img/project-neon.png",
    desc: "NEON aims to improve our understanding of human social intent detection and prediction. This is developed in open-ended contexts involving large unstructured social gatherings such as networking or mingling events. The project is developing novel frameworks to harvest and learn from social intentions and their relation to future outcomes.",
  },

  {
    name: "SEPTON",
    image: "img/project-septon.png",
    desc: "SEPTON aims to address the gap in the generic technologies and processes referring to the IT network infrastructure with a holistic approach towards reinforcing networked medical device security within the healthcare center premises. The project’s approach will result in a comprehensive cybersecurity toolkit providing tools and mechanisms to be used in hospitals and care centers.",
  },
];
