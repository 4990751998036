import React, { useEffect, useState } from "react";
import { CogIcon as Icon } from "@heroicons/react/24/solid";
import { projects } from "../data/projects";
import { HashLink as Link } from "react-router-hash-link";
import { handleScroll } from "../utils";
import { useLocation } from "react-router-dom";

export default function Projects() {
  const [shuffledProjects, setShuffledProjects] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    setShuffledProjects(shuffleArray(projects));
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      handleScroll(location.state.scrollTo);
    }
  }, [location]);

  return (
    <section id="projects" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <Icon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">Our Projects</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            We're proud of the impactful projects we've undertaken and the
            innovative solutions we've delivered. Our team is dedicated to
            tackling complex challenges and pushing the boundaries of what's
            possible.
          </p>
          <Link
            smooth
            to="/#workwithus"
            onClick={() => handleScroll("workwithus")}
            className="inline-block mt-8"
          >
            <button className="bg-white border-4 border-color-black hover:bg-gray-200 text-lg font-bold py-3 px-6 mt-10 rounded-full transition duration-300">
              Work with us
            </button>
          </Link>
        </div>

        <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3">
          {shuffledProjects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <img
                alt={project.name}
                className="w-full h-48 object-contain p-6"
                src={project.image}
              />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-3">{project.name}</h2>
                <p className="text-gray-600">{project.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
